import axios from "axios";

export let headers = {
  "Tenant-Access-Token": "Zjg5M2I0ZmM5ODBhZDA1ZGMzNzdhNDRjMmUxMGZkYWU=",
  "Tenant-Access-Key": "hgocdIiO9A8lWQ",
};

export const api = axios.create({
  baseURL: "https://immersive-game-india.confirmu.com",
  headers,
});

export const BASE_URL = "https://immersive-game-india.confirmu.com";
